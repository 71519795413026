<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>热烈欢迎唐山市丰南区领导一行到访祥业集团！</p>
          <p>发表时间: 2023-02-03 21:03</p>
        </div>
        <div class="video-box">
          <img
              src="../../assets/image/enterpriseNews/tangshan/ts1.jpg"
              alt=""
            />
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              2023年2月3日，唐山市丰南区领导一行到访祥业集团，就集团投资建设公共计算产业示范基地项目进行考察洽谈。集团主要领导接待并与会洽谈。
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <!-- <img
              src="../../assets/image/enterpriseNews/Housewarming/img1.png"
              alt=""
            /> -->
            <img
              src="../../assets/image/enterpriseNews/tangshan/ts2.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              集团副总主持会场，代表集团对各位领导的到来表示欢迎和感谢，并致以春天的问候和新年的祝福！会场欢声笑语不断。
              <!-- <br> -->
              <br>
              随后，丰南区区长表示，祥业科技曾多次为家乡唐山经济发展出力，区政府和人民深表感谢！对此，集团董事长马总表示：“我们将用更专业优异的答卷回报人民和社会。”会场响起热烈的掌声！
            </span>
          </div>
        </div>
        
        <div class="imgbox">
          <div class="imgbox-center">
            <!-- <img
              src="../../assets/image/enterpriseNews/Housewarming/img1.png"
              alt=""
            /> -->
            <img
              src="../../assets/image/enterpriseNews/tangshan/ts3.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
               针对各位领导关心的问题，集团云与计算总监详细介绍了集团情况和公共计算产业示范基地建设项目汇报。双方就公共计算产业示范基地项目，进行了深入交流探讨，丰南区各位领导对项目落成后，带给当地经济和科技发展的前景，表示高度赞赏！
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/tangshan/ts4.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
               面对祥业科技控股集团的热情接待，以及集团投资回报家乡，丰南区区长霍强代表区政府表示感谢，并愿意全力支持集团投资建设公共计算产业示范基地项目。希望祥业集团能深入与区政府进行对接，促进项目尽快落地，带动经济发展。
            </span>
            <br>
            <!-- <br> -->
            <span>
                这次活动的圆满结束，将对集团业务的发展，和集团品牌影响力产生积极作用。我们也将以此为契机，全身心投入，把更专业的技术和优质的产品，带给广大客户！
            </span>
          </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        
        <!-- <div class="textdescribe">
          <span
            >新的起点，新的起航，我们团结一心，奋发昂扬，坚持“以客户为中心，以奋斗者为本，以价值为纲”的核心价值观，为客户提供更加优质的产品与技术服务，为员工营造更加光明的发展前景，为公司书写更加华丽的篇章~</span
          >
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      padding: 30px 0 58px 0;
      // height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #aad4ee;
    //   height: 220px;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #f4effd;
        // height: 180px;
        color: #8735e4;
        font-size: 18px;
        padding: 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0;
        }
        img:nth-child(3) {
          margin: 0;
        }
      }
    }
    .bg-title {
      height: 28px;
      margin: 84px 0 60px 0;
      img{
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>